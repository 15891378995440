import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/sign-in',
        name: 'Sign In',
        component: () => import('../views/Signin.vue'),
    },
    {
        path: '/rooms',
        name: 'Rooms',
        component: () => import('../views/Rooms.vue'),
    },
    {
        path: '/scoreboard',
        name: 'Scoreboard',
        component: () => import('../views/Scoreboard.vue'),
    },
    {
        path: '/room/:roomID/lesson/:lessonID/activity/:activityID/scoreboard',
        name: 'ActivityScoreboard',
        component: () => import('../views/Scoreboard.vue'),
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue'),
    },
    {
        path: '/curriculum',
        name: 'Curriculum',
        component: () => import('../views/Curriculum.vue'),
    },
    {
        path: '/rooms/:slug?/:id/lessons',
        name: 'Lessons',
        props: true,
        component: () => import('../views/Lessons.vue'),
    },
    {
        path: '/learning-objective/room/:roomID/lesson/:lessonID',
        name: 'Learning Objectives',
        props: true,
        component: () => import('../views/LearningObjective.vue'),
    },
    {
        path: '/room/:roomID/lesson/:lessonID/activity/:id',
        name: 'Activity',
        props: true,
        component: () => import('../views/Activity.vue'),
    },
    // {
    //     path: '/activities/room-:roomID/lesson-:lessonID/activity-:id/story.html',
    //     name: 'Activity',
    //     props: true,
    //     component: () => import('../views/Activity.vue'),
    // },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (from) {
        store.state.fromLink = from;
    }
    if (typeof Storage !== 'undefined') {
        if (
            (store.state.user == null ||
                store.state.user == '' ||
                store.state.user == undefined) &&
            localStorage.getItem('user') != null
        ) {
            store.state.user = JSON.parse(localStorage.getItem('user'));
            if (store.state.user) {
                store.state.authHeaders.authorization =
                    'Bearer ' + store.state.user.access_token;
            } else {
                localStorage.removeItem('user');
                next({ path: '/sign-in' });
            }
        }
        if (
            (store.state.fromLink.name == null ||
                store.state.fromLink.name == '' ||
                store.state.fromLink.name == undefined) &&
            localStorage.getItem('fromLink')
        ) {
            store.state.fromLink = JSON.parse(localStorage.getItem('fromLink'));
        }
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (
        to.name !== 'Sign In' &&
        to.name !== 'Forgot Password' &&
        to.name !== 'Update Password'
    ) {
        if (store.state.user != null) {
            next();
        } else {
            localStorage.removeItem('user');
            next({ path: '/sign-in' });
        }
    } else if (to.name == 'Sign In') {
        if (store.state.user != null) {
            next({ path: '/' });
        } else {
            localStorage.removeItem('user');
            next();
        }
    } else {
        localStorage.removeItem('user');
        next();
    }
});

export default router;
