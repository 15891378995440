<template>
    <v-app>
        <v-main transition="fade">
            <router-view></router-view>
            <span class="version">V 0.0.205</span>
            <transition name="fade"
                ><response-msg
                    v-if="!!ResponseMsg"
                    :Msg="ResponseMsg"
                    :wrapperClass="ResponseClass"
                    :data="responseData"
                    @close="ResponseMsgClose"
                ></response-msg
            ></transition>
        </v-main>
        <p style="display: none; font-family: 'SingleDay-Regular'"></p>
        <p style="display: none; font-family: 'Poppins-ExtraBold'"></p>
    </v-app>
</template>

<script>
import ResponseMsg from '@/components/ResponseMsg.vue';
export default {
    name: 'App',
    components: {
        ResponseMsg,
    },
    methods: {
        /**
         * method to handle click on the Modal's Ok button
         */
        ResponseMsgClose() {
            console.log(!window.location.href.toString().includes('sign-in'));
            /** handle modal ok button for Pause-Resume  */
            if (this.$store.state.user != null) {
                if (this.$store.state.user.status == 'paused') {
                    this.$store.state.responseMsg = '';
                    this.$store.state.ResponseClass = '';
                    this.$store.state.responseData = '';
                    localStorage.removeItem('user');
                    this.$store.dispatch('userLogout');
                    clearInterval(this.$store.state.userStatusInterval);
                } else if (
                    !window.location.href.toString().includes('sign-in')
                ) {
                    localStorage.removeItem('user');
                    this.$store.dispatch('userLogout');
                    this.$store.state.responseMsg = '';
                    this.$store.state.ResponseClass = '';
                    this.$store.state.responseData = '';
                }
            } else {
                /** handle modal ok button click for log-in */
                if (!window.location.href.toString().includes('sign-in')) {
                    localStorage.removeItem('user');
                    this.$store.dispatch('userLogout');
                }
                this.$store.state.responseMsg = '';
                this.$store.state.ResponseClass = '';
                this.$store.state.responseData = '';
            }
        },
        /**
         * method to call the 'checkUserStatus' API after every 5 seconds
         * if user status is set to paused, it will show a popup
         */
        callCheckUserStatus() {
            this.$store.dispatch('checkUserStatus').then(() => {
                this.$store.state.userStatusInterval = setInterval(() => {
                    this.$store.dispatch('checkUserStatus');
                }, 5000);
                if (this.$store.state.user.status == 'paused') {
                    this.$store.state.commit(
                        'setResponseMessage',
                        'You have been paused by your teacher. Please contact your teacher.'
                    );
                    this.$store.state.commit('setResponseClass', 'error');
                    this.$store.state.commit('setActionInProgress', false);
                    clearInterval(this.$store.state.userStatusInterval);
                }
            });
        },
    },
    computed: {
        ResponseMsg: {
            get() {
                return this.$store.state.responseMsg;
            },
            set(value) {
                this.$store.state.responseMsg = value;
            },
        },
        ResponseClass() {
            return this.$store.state.ResponseClass;
        },
        responseData() {
            return this.$store.state.responseData;
        },
        actionInProgress() {
            return this.$store.state.actionInProgress;
        },
    },
    mounted() {
        this.$store.state.fetching = false;
        /** start the script to check user's status (active or paused) */
        if (this.$store.state.user != null) {
            this.callCheckUserStatus();
        }

        this.$store.dispatch('initializeDatabase');
        console.log(process.env.VUE_APP_API_END_POINT);
        console.log(process.env.VUE_APP_SERVER_KEY);
        console.log(process.env.VUE_APP_SERVER_SECRET);
    },
    created() {
        var _this = this;
        window.addEventListener('load', playBgMusicAfterReferesh);
        function playBgMusicAfterReferesh() {
            if (
                _this.$store.state.user != null &&
                _this.$store.state.bgMusic == null
            ) {
                _this.$store.dispatch(
                    'playBackgroundMusic',
                    'supersci-bg-music.mp3'
                );
                // document.removeEventListener('click', playBgMusicAfterReferesh);
            }
        }
        // var volumeData = JSON.parse(localStorage.getItem('volume'));
        // if(volumeData != null){
        //     // _this.$store.state.volume = volumeData.volume;
        //     _this.$store.state.bgMusic.volume =  volumeData.volume;
        //     _this.$store.state.bgSoundMute =  volumeData.bgSoundMute;
        // //     localStorage.removeItem('volume');
        // }
        // setTimeout(() => {
        //     document.addEventListener('click', playBgMusicAfterReferesh);
        // }, 100);
    },
    watch: {},
};
</script>
<style>
@font-face {
    font-family: 'Lomo';
    src: local('Lomo'), url('~@/assets/Fonts/Font.TTF') format('truetype');
}

@font-face {
    font-family: 'Digi';
    src: local('Digi'), url('~@/assets/Fonts/DS-DIGIB.TTF') format('truetype');
}

@font-face {
    font-family: 'Tucker Script';
    src: local('Tucker Script'),
        url('~@/assets/Fonts/TuckerScriptRg.TTF') format('truetype');
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: local('Poppins-ExtraBold'),
        url('~@/assets/Fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'SingleDay-Regular';
    src: local('SingleDay-Regular'),
        url('~@/assets/Fonts/SingleDay-Regular.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

header .v-toolbar__content {
    height: auto !important;
    padding: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.25s;
}

.fade-enter-active {
    transition-delay: 0.25s;
}

.slide-fade-enter-active {
    transition: all 1s ease-in-out;
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(280px);
    opacity: 0;
}

/* right slide fade */
.right-slide-fade-enter-active {
    transition: all 1s ease-in-out;
}

.right-slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.right-slide-fade-enter,
.right-slide-fade-leave-to {
    transform: translateX(200px);
    opacity: 0;
}

/*  */

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.version {
    position: fixed;
    top: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.2); */
    color: #fff;
    z-index: 999;
    font-size: 10px;
}

.v-application button {
    transition: 0.3s all;
}

.v-application a {
    transition: 0.3s all;
}

.pageLoader {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 2555, 255, 0.9);
}

.pageLoader .v-progress-circular {
    height: calc(20vh) !important;
    width: calc(15vw) !important;
    margin: auto;
}

#curriculumPage,
#HomePage,
.bg-container {
    background-position-x: left;
    background-attachment: fixed;
    padding: 0px;
}

.standard-bg {
    background: url('~@/assets/main-bg.png');
    background-size: 100% 105%;
}

.custom-font {
    font-family: 'Lomo', sans-serif;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke: 3px black;
}

.full-screen-loader .progressLoader {
    width: calc(20vw) !important;
    height: calc(20vh) !important;
    margin: auto;
}

.full-screen-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    align-self: start;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
}

.moveable-control-box {
    display: none !important;
}

.skip,
button.skip.v-btn,
button.skip.v-btn.v-btn--disabled.custom-font {
    position: fixed;
    right: 0;
    bottom: 50px;
    background-color: rgba(255, 255, 255, 0.8) !important;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    z-index: 998;
    padding: 10px 25px !important;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

button.skip.v-btn,
button.skip.v-btn.v-btn--disabled.custom-font {
    color: #fff !important;
    display: inline-block;
    height: auto !important;
}

.skip:hover,
button.skip.v-btn:hover,
button.skip.v-btn.v-btn--disabled.custom-font:hover {
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    color: #00b917 !important;
}

.timer {
    position: fixed;
    right: 0;
    top: 0;
}

@media screen and (max-width: 767px) {
    .large-text {
        font-size: 26px;
    }

    .medium-text {
        font-size: 18px;
    }

    #curriculumPage,
    #HomePage,
    .bg-container {
        background-size: cover;
    }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
    .large-text {
        font-size: 34px;
    }

    .medium-text {
        font-size: 26px;
    }

    #curriculumPage,
    #HomePage,
    .bg-container {
        background-size: cover;
    }
}

@media screen and (min-width: 1001px) {
    .large-text {
        font-size: 50px;
    }

    .medium-text {
        font-size: 36px;
    }

    #curriculumPage,
    #HomePage,
    .bg-container {
        background-size: 100% 105%;
    }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 601px) and (min-width: 767px) {
}

@media screen and (max-width: 959px) and (min-width: 768px) {
}

@media screen and (max-width: 1263px) and (min-width: 960px) {
}

@media screen and (min-width: 1264px) {
}
</style>
