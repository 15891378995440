<template>
    <v-container class="home-page-container" fluid fill-height style="padding: 0px" id="HomePage" v-bind:style="{
        'background-image': 'url(' + require(`@/assets/img/backgrounds/${currentBackground}`) + ')',
    }">

        <Volume></Volume>

        <transition name="fade-transition">
            <div class="skip custom-font" v-if="showIntro && show" @click="skipVideo">
                Skip Intro
            </div>
        </transition>

        <transition name="fade-transition">
            <div class="intro-video-wrapper" v-if="showIntro && $store.state.user.type != 'teacher' && show">
                <div class="myVideoWrapper">
                    <video @click="videoPlay" :muted="$store.state.mute || introvideoMute" autoplay id="myVideo"
                        @ended="videoEnded" :poster="require('@/assets/img/home-video-poster.png')"
                        @loadeddata="checkLoading">
                        <source src="@/assets/videos/updated-game-intro.mp4" type="video/mp4" />
                    </video>
                    <v-icon v-if="$store.state.mute || introvideoMute" class="mute-btn" @click="mute_or_unmute">
                        volume_off
                    </v-icon>
                    <v-icon v-if="!$store.state.mute || !introvideoMute" class="mute-btn" @click="mute_or_unmute">
                        volume_up
                    </v-icon>
                </div>
            </div>
        </transition>

        <transition name="fade-transition">
            <div class="full-screen-loader" v-if="
                videoLoading &&
                !introWatched &&
                $store.state.user.type != 'teacher' &&
                !show
            ">
                <v-progress-circular indeterminate color="#000000" class="progressLoader">
                </v-progress-circular>
            </div>
        </transition>

        <transition name="fade-transition">
            <v-container>
                <div class="menus-container" v-if="!showIntro && introWatched && show">
                    <ul class="menus-heading">
                        <li class="menu-list-item">
                            <router-link to="/rooms">ROOMS
                                <v-icon x-large class="arrow-icon">east</v-icon>
                            </router-link>
                        </li>

                        <li class="menu-list-item">
                            <router-link to="/">SCOREBOARD
                                <v-icon x-large class="arrow-icon">west</v-icon>
                            </router-link>
                        </li>
                        <li class="menu-list-item">
                            <router-link to="/settings">SETTINGS
                                <v-icon x-large class="arrow-icon">north</v-icon>
                            </router-link>
                        </li>
                        <li class="menu-list-itemt" v-if="User.type == 'teacher'">
                            <router-link to="/curriculum">CURRICULUM</router-link>
                        </li>
                    </ul>
                </div>
            </v-container>
        </transition>
    </v-container>
</template>

<script>
import jwt_decode from "jwt-decode";
import Volume from '@/components/Volume';
export default {
    name: 'Home',
    components: { Volume },
    data() {
        return {
            showIntro: false,
            videoLoading: false,
            show: false,
            introvideoMute: true,
            currentBackground: 'home-page-student-bg.png',
            volume_range: 0,
        };
    },
    mounted() {
        // localStorage.removeItem('volume');
        const token = JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user"))["access_token"];
        console.log("exp:" + jwt_decode(token).exp);
        if (jwt_decode(token).exp * 1000 < new Date().getTime()) {
            console.log((jwt_decode(token).exp * 1000) + "," + new Date().getTime());
            console.log('Time Expired');
        }
        if (this.$store.state.user != null) {
            let isIntroWatched = JSON.parse(localStorage.getItem('user'))['introWatched'];
            console.log(isIntroWatched);
            if (this.$store.state.user.type == 'teacher' || isIntroWatched == true) {
                this.showIntro = false;
                this.introWatched = true;
                this.videoLoading = false;
                this.show = true;
            } else {
                this.showIntro = true;
                this.videoLoading = true;
                this.show = true;
            }
        } else {
            localStorage.clear();
            this.$store.dispatch('stopBackgroundMusic', 'supersci-bg-music.mp3');
            this.$router.push({ path: '/sign-in' });
        }

    },
    methods: {
        // toggleBgSound() {
        //     if(this.$store.state.bgSoundMute == false){
        //         alert("if");
        //         this.$store.state.volume = 0;
        //         this.$store.state.bgMusic.volume = 0;
        //         this.$store.state.bgSoundMute = true;
        //     }else{
        //         alert("else");
        //         this.$store.state.volume = 99;
        //         this.$store.state.bgMusic.volume = 0.9;
        //         this.$store.state.bgSoundMute = false;
        //     }
        // },
        mute_or_unmute() {
            this.introvideoMute = !this.introvideoMute;
        },
        videoPlay() {
            var video = document.getElementById('myVideo');
            if (video.paused == true) {
                video.play();
                video.title = 'Click to pause video';
            } else {
                video.pause();
                video.title = 'Click to play video';
            }
        },
        videoEnded() {
            this.showIntro = false;
            this.introWatched = true;
            this.$store.dispatch('fadeUpBackgroundMusic');
            let userData = JSON.parse(localStorage.getItem('user'));
            localStorage.setItem('user', JSON.stringify({ ...userData, introWatched: true }));
        },
        checkLoading() {
            this.$store.dispatch('fadeDownBackgroundMusic');
            this.videoLoading = false;
        },
        skipVideo() {
            this.videoEnded();
            window.location.reload()
        },
    },
    computed: {
        User() {
            return this.$store.state.user;
        },
        introWatched: {
            set(value) {
                this.$store.state.introWatched = value;
            },
            get() {
                return this.$store.state.introWatched;
            },
        },
        showRooms() {
            return !!this.$store.state.RoomDB;
        }
    }
}
</script>
<style scoped>
/* .volume-container{
    position: absolute;
    top: 1%;
    right: 1.5%;
    min-width: 50px;
    min-height: 45px;
    display: block;
}

.volume-icon {
    right: 2%!important;
    top: 1%;
    font-size: 45px!important;
    transform: rotate(180deg);
    cursor: pointer;
    position: fixed!important;
    color: rgba(0,0,0,.70)!important;
}
.volume-slider{
    position: absolute;
    right: 60px;
    top: 12px;
    display: none;
    width: 120px;
    /* accent-color: #BF6902; */
/* }

.volume-container:hover .volume-slider{
    display: block;
}

.volume-container:hover{
    min-width: 170px;
} */

.home-page-container {
    background-size: 100% 100%;
    /* animation: slide 10s linear;    */
}

/* @keyframes slide {
    0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: translate3d(-1692px, 0, 0);
  }
} */

.menus-container {
    position: absolute;
    left: 70vw;
    top: 33vh;
    transform: translate(-50%);
    width: max-content;
    height: fit-content;
    border: solid 7px #4E68A3;
    overflow: visible;
    background: #4E68A3;
    box-shadow: -12px 12px 2px 0px rgb(0 0 0 / 10%);
    padding: 0% 1%;
    border-radius: 10px;
}

.menus-heading {
    color: #FFFFFF;
    margin-top: calc(0.75vh + 0.75vw);
    margin-bottom: calc(0.75vh + 0.75vw);
    border: solid 7px #FFFFFF;
    border-radius: 15px;
    padding-top: 2%;
    padding-bottom: 3%;
}

.menus-heading li {
    margin: 5px 2vw !important;
    margin-left: calc(1.15vh + 1.15vw) !important;
}

.menu-list-item {
    width: fit-content;
}

.menu-list-item * {
    color: #FFFFFF;
}

.menus-heading li a {
    font-size: calc(1.4vh + 1.4vw);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    font-family: 'Lomo';
}

.menu-list-item::marker {
    font-size: calc(1.75vh + 1.75vw);
    margin: 0;
    padding: 0;
    line-height: 1px;
    font-family: 'Lomo';
}

.menu-list-item:hover * {
    color: #00b917 !important;
}

.menu-list-item:hover::marker {
    color: #00b917 !important;
}

.arrow-icon {
    font-size: 5vh;
    color: #FFFFFF !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 3px 5px 4px rgba(0, 0, 0, 0.5);
}

/* .menus-heading li a:hover   ~ .menus-heading li a{
    color: #00b917!important;
}  */
/* .arrow-icon:hover .menus-heading li a {
    color: #00b917!important;
} */
/* .arrow-icon:hover{
    color: #00b917;

} */

/* .arrow-icon:not(.green):hover {
    color: white;
}*/

/* @media (hover: none) {
    .menus-heading li a:hover {
        color: #00b917!important;
    }
}  */
.myVideoWrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#myVideo {
    cursor: pointer;
    max-width: calc(95vw);
    max-height: calc(95vh);
}

.myVideoWrapper .mute-btn {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 15px;
    opacity: 0.5;
}

.myVideoWrapper .mute-btn:hover {
    opacity: 1;
}

.intro-video-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

/* 
.homePageMenu li a,
.homePageMenu li {
    font-family: 'Lomo', sans-serif;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke: 3px black;
}

li.large-text {
    line-height: 1;
    width: min-content;
    margin: 15px 0px;
    transition: all 0.3s;
}
li.large-text:hover::marker,
.homePageMenu li a:hover {
    color: #00b917;
} */
@media screen and (max-width: 767px) {
    .v-image.v-responsive.theme--light .v-responsive__content {
        display: none;
    }

    #HomePage {
        background-position-x: left;
    }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
    #HomePage {
        background-position-x: left;
    }
}
</style>
